import React, {Component} from 'react';

class Header extends Component {
    render() {
        return (
            <>
                <h1>Hangman</h1>
                <p>Find the hidden word - Enter a Letter</p>
            </>
        );
    }
}

export default Header;