import './App.css';
import Header from "./components/Header";
import Figure from "./components/Figure";
import WrongLetters from "./components/WrongLetters";
import Word from "./components/Word";
import React, {useState, useEffect} from 'react';
import Popup from "./components/Popup";
import Notification from "./components/Notification";
import {notify} from "./helpers/helpers";

const words = ['application', 'programming', 'interface', 'kelwin'];
let selectedWord = words[Math.floor(Math.random() * words.length)];

function App() {
    const [playable, setPlayable] = useState(true);
    const [correctLetters, setCorrectLetters] = useState([]);
    const [wrongLetters, setWrongLetters] = useState([]);
    const [showNotification, setShowNotification] = useState(false);


    useEffect(() => {
        const handleKeyDown = event => {
            const {key, keyCode} = event;
            if (playable && keyCode >= 65 && keyCode <= 90) {
                const letter = key.toLowerCase();
                if (selectedWord.includes(letter)) {
                    if (!correctLetters.includes(letter)) {
                        setCorrectLetters(currentLetters => [...currentLetters, letter]);
                    } else {
                        //showNotification();
                        notify(setShowNotification);
                    }
                } else {
                    if (!wrongLetters.includes(letter)) {
                        setWrongLetters(wrongLetters => [...wrongLetters, letter])
                    } else {
                        //showNotification();
                        notify(setShowNotification);
                    }
                }
            }
        }
        window.addEventListener('keydown', handleKeyDown);
        return () => window.removeEventListener('keydown', handleKeyDown);
    }, [correctLetters, wrongLetters, playable]);

    function playAgain() {
        setPlayable(true);
        //Empty Arrays
        setCorrectLetters([]);
        setWrongLetters([]);
        const random = Math.floor(Math.random() * words.length);
        selectedWord = words[random];
    }

    return (
        <div>
            <Header/>
            <div className="game-container">
                <Figure wrongLetters={wrongLetters}/>
                <WrongLetters wrongLetters={wrongLetters}/>
                <Word selectedWord={selectedWord} correctLetters={correctLetters}/>
            </div>
            <Popup correctLetters={correctLetters} wrongLetters={wrongLetters} selectedWord={selectedWord}
                   setPlayable={setPlayable} playAgain={playAgain}/>
            <Notification showNotification={showNotification}/>
        </div>
    );
}

export default App;
